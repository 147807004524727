try {
    window._ = require('lodash');
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('select2');
    window.toastr = require('toastr');
    window.bootbox = require("bootbox");
} catch (e) {
    console.log("error", e);
}